<template>
  <section class="preview">
    <div class="container">
      <div class="preview__content">
        <div class="preview__description">
          <h1 class="preview__title">{{
              $vuetify.lang.t("$vuetify.main.preview.mainTitle")
            }}</h1>
          <img class="preview__secondary-img" src="@/assets/images/devices.png" alt="devices">
          <h3 class="decor-text">{{
              $vuetify.lang.t("$vuetify.main.preview.decorTextPart1")
            }}</h3>
          <h3 class="decor-text mt-n2">{{
              $vuetify.lang.t("$vuetify.main.preview.decorTextPart2")
            }}</h3>
          <a :href="linkToDashboard" target="_blank">
            <button class="main-btn preview__main-btn">{{
                $vuetify.lang.t("$vuetify.main.preview.previewMainBtn")
              }}</button>
          </a>
          <span class="preview__addition">{{
              $vuetify.lang.t("$vuetify.main.preview.additionText", price)
            }}</span>
        </div>
        <div class="preview__picture">
          <img class="preview__main-img" src="@/assets/images/main-picture.png" alt="talks">
        </div>
      </div>
    </div>
    <article class="preview__footer">
      <div class="container">
        <ul class="preview__dignity-list">
          <li class="preview__dignity-item">
            <h4 class="preview__dignity-title">{{
                $vuetify.lang.t("$vuetify.main.preview.dignityTitle1")
              }}</h4>
            <span class="preview__addition">{{
                $vuetify.lang.t("$vuetify.main.preview.dignityTitle2")
              }}</span>
          </li>
          <li class="preview__dignity-item">
            <h4 class="preview__dignity-title">{{
                $vuetify.lang.t("$vuetify.main.preview.dignityTitle3")
              }}</h4>
            <span class="preview__addition">{{
                $vuetify.lang.t("$vuetify.main.preview.dignityAdditionText1")
              }}</span>
          </li>
          <li class="preview__dignity-item">
            <h4 class="preview__dignity-title">{{
                $vuetify.lang.t("$vuetify.main.preview.dignityAdditionText2")
              }}</h4>
            <span class="preview__addition">{{
                $vuetify.lang.t("$vuetify.main.preview.dignityAdditionText3")
              }}</span>
          </li>
        </ul>
      </div>
    </article>
  </section>
</template>

<script>
export default {
  name: 'PreviewSection',
  data () {
    return {
      linkToUserReg: process.env.VUE_APP_DASHBOARD + '/auth',
      linkToDashboard: process.env.VUE_APP_DASHBOARD + '/patientRegForm',
      price: ''
    }
  },
  created () {
    this.price = process.env.VUE_APP_SESSION_PRICE
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  text-align: center;
}
.preview {
  padding: 60px 0 15px;

  &__content {
    display: flex;
    justify-content: space-between;
  }

  &__description {
    text-align: center;
    max-width: 480px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 20px 0;
  }

  &__title {
    max-width: 370px;
    font-size: 2rem;
    line-height: normal;
    font-weight: 500;
    color: $darkGrey;
  }
  &__secondary-img {
    position: relative;
    top: 1.8rem;
    left: 30px;
    max-width: 290px;
  }
  &__main-btn {
    margin-top: 2rem;
    //padding: 1.4rem 1rem;
  }
  &__addition {
    margin-top: 20px;
    font-weight: normal;
    font-size: 1rem;
    color: $darkTextColor;
  }
  &__picture {
    display: flex;
    align-items: center;
  }
  &__main-img {
    width: 100%;
    height: auto;
    max-width: 600px;
    max-height: 600px;
  }
  &__footer {
    border-top: 2px solid $lightColor;
    margin-top: 50px;
  }
  &__dignity-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    list-style: none;
    text-align: center;
    color: $darkTextColor;
  }
  &__dignity-title {
    font-size: 1.9rem;
    font-weight: normal;
  }
}
@media screen and (max-width: 768px) {
  .preview__content {
    flex-wrap: wrap;
    justify-content: center;
  }
}
</style>
