<template>
  <section class="about">
    <div class="container">
      <h2 class="section-title">{{
          $vuetify.lang.t("$vuetify.main.about.sectionTitle")
        }}</h2>
      <h3 class="section-subtitle about__section-subtitle">{{
          $vuetify.lang.t("$vuetify.main.about.sectionSubtitle")
        }}</h3>
      <ul class="about__cards">
        <li class="about__item" :class="{'about__item--even': card.id % 2 === 0}" v-for="card in aboutList"
            :key="card.id">
          <div class="about__picture">
            <img class="about__img" :src="card.src" alt="problems">
          </div>
          <p class="about__description">{{ card.description }}</p>
        </li>
      </ul>
      <div class="about__bg-border about__bg-border--sm"></div>
      <div class="about__bg-border about__bg-border--md"></div>
      <div class="about__bg-border about__bg-border--lg"></div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AboutSection',
  computed: {
    aboutList () {
      const type = this.$store.getters['app/getSelectedLocale'] === 'ru' ? 'problems-4rus' : 'problems-4'
      return [
        {
          id: 1,
          description: this.$vuetify.lang.t('$vuetify.main.about.cards.card1'),
          src: require('@/assets/images/problems-1.png')
        },
        {
          id: 2,
          description: this.$vuetify.lang.t('$vuetify.main.about.cards.card2'),
          src: require('@/assets/images/problems-2.png')
        },
        {
          id: 3,
          description: this.$vuetify.lang.t('$vuetify.main.about.cards.card3'),
          src: require('@/assets/images/problems-3.png')
        },
        {
          id: 4,
          description: this.$vuetify.lang.t('$vuetify.main.about.cards.card4'),
          src: require(`@/assets/images/${type}.png`)
        },
        {
          id: 5,
          description: this.$vuetify.lang.t('$vuetify.main.about.cards.card5'),
          src: require('@/assets/images/problems-5.png')
        },
        {
          id: 6,
          description: this.$vuetify.lang.t('$vuetify.main.about.cards.card6'),
          src: require('@/assets/images/problems-6.png')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  text-align: center;
}
.about {
  padding-top: 55px;
  padding-bottom: 115px;
  background-color: $backgroundColor;

  &__section-subtitle {
    background-color: $backgroundColor;
  }

  &__cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 30px;
    list-style: none;
    margin: 35px 0 0;
    padding: 0 20px;
  }

  &__item {
    flex-basis: auto;
    max-width: 320px;
    border-radius: 6px;
    overflow: hidden;
    z-index: 1;
  }

  &__item--even {
    border: 5px solid $lightColor;
  }

  &__picture {
    display: flex;
    justify-content: center;
    max-height: 180px;
    padding: 20px 30px;
    background-color: #ffffff;
  }

  &__img {
    max-height: inherit;
    max-width: inherit;
    object-fit: contain;
  }

  &__description {
    font-size: 1rem;
    font-weight: normal;
    width: 100%;
    height: 100%;
    padding: 5px 10px;
    margin: 0;
    line-height: 1.3;
    text-align: center;
    background-color: $lightColor;
    color: #ffffff;
  }

  &__bg-border {
    position: absolute;
    border: 5px solid $lightColor;
  }

  &__bg-border--sm {
    top: 10%;
    right: 0;
    width: 100%;
    height: 100%;
    max-width: 225px;
    max-height: 125px;
  }

  &__bg-border--md {
    top: 35%;
    left: 2%;
    width: 100%;
    height: 100%;
    max-width: 300px;
    max-height: 250px;
  }

  &__bg-border--lg {
    top: 43%;
    right: 0;
    width: 100%;
    height: 100%;
    max-width: 600px;
    max-height: 440px;
  }
}
</style>
