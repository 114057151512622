<template>
  <section class="check">
    <div class="container">
      <h2 class="section-title">{{
          $vuetify.lang.t("$vuetify.main.check.sectionTitle")
        }}</h2>
      <h3 class="section-subtitle check__section-subtitle">{{
          $vuetify.lang.t("$vuetify.main.check.sectionSubtitle")
        }}</h3>
      <div class="check__content">
        <div class="check__picture">
          <img class="check__img" src="@/assets/images/check-img.png" alt="people">
          <span class="decor-text">{{
              $vuetify.lang.t("$vuetify.main.check.decorText")
            }}</span>
        </div>
        <ul class="check__list">
          <li class="check__item" v-for="check in checkList" :key="check.id">
            <h3 class="check__subtitle">{{ check.title }}</h3>
            <div class="check__number-wrapper">
              <div class="check__number">{{ check.id }}</div>
            </div>
            <p class="check__description">{{ check.description }}</p>
          </li>
        </ul>
      </div>
      <a :href="linkToDashboard" target="_blank">
        <button class="main-btn check__button">{{
            $vuetify.lang.t("$vuetify.main.check.button")
          }}</button>
      </a>
    </div>
  </section>
</template>

<script>
export default {
  name: 'CheckSection',
  data () {
    return {
      linkToDashboard: process.env.VUE_APP_DASHBOARD + '/patientRegForm'
    }
  },
  computed: {
    checkList () {
      return [
        {
          id: 1,
          title: this.$vuetify.lang.t('$vuetify.main.check.checkList.itemTitle1'),
          description: this.$vuetify.lang.t('$vuetify.main.check.checkList.itemDescription1')
        },
        {
          id: 2,
          title: this.$vuetify.lang.t('$vuetify.main.check.checkList.itemTitle2'),
          description: this.$vuetify.lang.t('$vuetify.main.check.checkList.itemDescription2')
        },
        {
          id: 3,
          title: this.$vuetify.lang.t('$vuetify.main.check.checkList.itemTitle3'),
          description: this.$vuetify.lang.t('$vuetify.main.check.checkList.itemDescription3')
        },
        {
          id: 4,
          title: this.$vuetify.lang.t('$vuetify.main.check.checkList.itemTitle4'),
          description: this.$vuetify.lang.t('$vuetify.main.check.checkList.itemDescription4')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  text-align: center;
}
.check {
  padding: 60px 0;

  &__section-subtitle {
    margin-top: 10px;
  }
  &__content {
    display: flex;
    justify-content: space-evenly;
    gap: 30px;
    margin-top: 40px;
  }
  &__picture {
    align-self: center;
    max-width: 530px;
  }
  &__img {
    width: 100%;
    height: auto;
  }
  &__list {
    position: relative;
    height: fit-content;
    padding: 20px 0 0 60px;
    list-style: none;
    border-left: 2px solid $darkTextColor;
    text-align: left;
    padding-left: 40px !important;
  }
  &__item {
    max-width: 340px;
  }
  &__subtitle {
    font-size: 1.3rem;
    font-weight: normal;
    color: $lightColor;
  }
  &__number-wrapper {
    position: absolute;
    left: 0;
    width: 40px;
    height: 40px;
    background-color: $lightColor;
    transform: rotateZ(45deg) translateX(-74%);
  }
  &__number {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 2rem;
    transform: rotateZ(-45deg);
    color: #ffffff;
  }
  &__description {
    font-size: 1rem;
    word-wrap: normal;
    min-height: 72px;
    color: $darkTextColor;
  }
  &__button {
    margin: 50px auto 0;
    padding-left: 60px;;
    padding-right: 60px;;
  }
  @media screen and (max-width: 768px) {
    &__content {
      flex-wrap: wrap;
    }
    &__list {
      padding: 20px 0 0 40px;
      margin-left: 20px;
    }
  }
}
</style>
