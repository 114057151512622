<template>
  <section class="contacts">
    <div class="container">
      <h2 class="section-title">{{
          $vuetify.lang.t("$vuetify.main.contacts.mainTitle")
        }}</h2>
      <p class="contacts__text">{{
          $vuetify.lang.t("$vuetify.main.contacts.descriptionText")
        }}</p>
      <ul class="contacts__list">
        <li class="contacts__item" :class="{'contacts__item--disabled' : contact.id === 0}" v-for="contact in contactsList" :key="contact.id">
          <div class="contacts__item-devider" v-if="contact.id !== 0"></div>
          <div class="contacts__item-content">
            <h3 class="section-subtitle contacts__subtitle">{{ contact.telephone }}</h3>
            <p class="contacts__item-description" lang="uk">{{ contact.description }}</p>
          </div>
        </li>
      </ul>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ContactsSection',
  computed: {
    contactsList () {
      return [
        {
          id: 0,
          telephone: '7333',
          description: this.$vuetify.lang.t('$vuetify.main.contacts.contactsList.itemDescription1')
        },
        {
          id: 1,
          telephone: '0800 500 335',
          description: this.$vuetify.lang.t('$vuetify.main.contacts.contactsList.itemDescription2')
        },
        {
          id: 2,
          telephone: '0800 210 160',
          description: this.$vuetify.lang.t('$vuetify.main.contacts.contactsList.itemDescription3')
        },
        {
          id: 3,
          telephone: '0800 500 225',
          description: this.$vuetify.lang.t('$vuetify.main.contacts.contactsList.itemDescription4')
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  text-align: center;
}
.contacts {
  padding: 70px 0;
  background-color: $backgroundColor;
  &__text {
    margin-top: 60px;
    color: $darkTextColor;
    font-size: 1.6rem;
    line-height: normal;
    letter-spacing: 1px;
    text-align: left;
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 20px;
    padding: 0;
    margin-top: 70px;
    list-style: none;
  }
  &__item {
    position: relative;
    display: flex;
    align-items: start;
    justify-content: space-between;
    max-width: 270px;
    width: 100%;
  }
  &__item--disabled {
    justify-content: center;
  }
  &__item-content {
    display: flex;
    flex-direction: column;
    align-items: start;
    max-width: 235px;
    width: 100%;
  }
  &__subtitle {
    font-size: 1.8rem;
    color: $lightColor;
    font-weight: normal;
  }
  &__item-description {
    font-size: 1rem;
    flex-wrap: wrap;
    margin-top: 20px;
    text-align: start;
    color: $darkTextColor;
    hyphens: auto;
  }
  &__item-devider {
    transform: translateY(50%);
    display: block;
    height: 80px;
    width: 2px;
    border-left: 1px solid $lightColor;
  }
}
</style>
